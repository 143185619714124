












import { Component, Vue } from 'vue-property-decorator'
import CanvasFun from '@/utils/canvas-ocean.js'

@Component({
    components: {}
})
export default class Login extends Vue {
    mounted() {
        CanvasFun.canvasOcean('canvas', document.getElementById('canvasBox'))
    }
}
