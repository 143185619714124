export default {
    canvasOcean(canvasId, canvasBox) {
        const c = document.getElementById(canvasId).getContext('2d')
        const canvas = c.canvas
        const vertices = []

        // Effect Properties
        const vertexCount = 4000
        const vertexSize = 3
        const oceanWidth = 204
        const oceanHeight = -20
        const gridSize = 22
        const waveSize = 8
        const perspective = 80

        // Common variables
        const depth = (vertexCount / oceanWidth) * gridSize
        let frame = 0
        const { sin, cos, PI } = Math

        // const gradient = c.createLinearGradient(0, canvasBox.offsetHeight, canvasBox.offsetWidth, canvasBox.offsetHeight)
        // gradient.addColorStop(0, '#2498FF')
        // gradient.addColorStop(0.7, '#2061F3')
        // gradient.addColorStop(1, '#1D47EE')

        // Render loop
        const loop = () => {
            const rad = (sin(frame / 100) * PI) / 20
            const rad2 = (sin(frame / 50) * PI) / 10
            frame++
            canvas.width = canvasBox.offsetWidth
            canvas.height = canvasBox.offsetHeight - 70

            c.fillStyle = 'transparent'
            c.fillRect(0, 0, canvas.width, canvas.height)
            c.save()
            c.translate(canvas.width / 2, canvas.height / 2)

            c.beginPath()
            vertices.forEach((vertex, i) => {
                // const ni = i + oceanWidth
                let x = vertex[0] - (frame % (gridSize * 2))
                let z = vertex[2] - ((frame * 2) % gridSize) + (i % 2 === 0 ? gridSize / 2 : 0)
                const wave = cos(frame / 45 + x / 50) - sin(frame / 20 + z / 50) + sin(frame / 30 + (z * x) / 10000)
                let y = vertex[1] + wave * waveSize
                const a = Math.max(0, 1 - Math.sqrt(x ** 2 + z ** 2) / depth)
                let tx, ty, tz

                y -= oceanHeight

                // Transformation variables
                tx = x
                ty = y
                tz = z

                // Rotation Y
                tx = x * cos(rad) + z * sin(rad)
                tz = -x * sin(rad) + z * cos(rad)

                x = tx
                y = ty
                z = tz

                // Rotation Z
                tx = x * cos(rad) - y * sin(rad)
                ty = x * sin(rad) + y * cos(rad)

                x = tx
                y = ty
                z = tz

                // Rotation X

                ty = y * cos(rad2) - z * sin(rad2)
                tz = y * sin(rad2) + z * cos(rad2)

                x = tx
                y = ty
                z = tz

                x /= z / perspective
                y /= z / perspective

                if (a < 0.01) return
                if (z < 0) return

                c.globalAlpha = a
                // c.fillStyle = `hsl(${70 + wave * 10}deg, 80%, 50%)`
                // c.fillStyle = 'hsla(0, 0%, 90%, 1)'

                c.fillStyle = '#ffffff'
                c.fillRect(x - (a * vertexSize) / 2, y - (a * vertexSize) / 2, a * vertexSize, a * vertexSize)
                c.globalAlpha = 1
            })
            c.restore()

            // Post-processing
            c.drawImage(canvas, 0, 0)

            c.globalCompositeOperation = 'screen'
            c.filter = 'blur(16px)'
            c.drawImage(canvas, 0, 0)
            c.filter = 'blur(0)'
            c.globalCompositeOperation = 'source-over'

            requestAnimationFrame(loop)
        }

        // Generating dots
        for (let i = 0; i < vertexCount; i++) {
            const x = i % oceanWidth
            const y = 0
            const z = (i / oceanWidth) >> 0
            const offset = oceanWidth / 2
            vertices.push([(-offset + x) * gridSize, y * gridSize, z * gridSize])
        }

        loop()
    }
}
